import { useEcTitle } from '@toasttab/ec-title'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { useUserStatusMutation } from '../../../data'
import { UsernameForm, UsernameFormData } from './UsernameForm'
import { useLoginDispatch } from '../LoginProvider'

export const Username = (
  props: {
    username?: string
    prefilledUsername?: string
  }
) => {
  const dispatch = useLoginDispatch()
  const { username, prefilledUsername } = props
  const { mutateAsync: userStatus, reset: resetUserStatus } =
    useUserStatusMutation()
  const { t } = useTranslation('login')

  useEcTitle(t('username'))

  const onSubmit = useCallback(
    async (data: UsernameFormData) => {
      resetUserStatus()

      const formUsername = data.username.trim()

      const result  = await userStatus({
        email: formUsername
      })

      const { status, loginNonce, isCaptchaRequired } = result

      if (!loginNonce) {
        throw new Error('Login nonce not found')
      }

      dispatch({
        type: 'username-entered',
        status: status,
        username: formUsername,
        loginNonce: loginNonce,
        isCaptchaRequired: isCaptchaRequired
      })
    },
    []
  )

  useEffect(() => {
      if (prefilledUsername) {
         onSubmit({
           username: prefilledUsername
         })
      }
  }, [prefilledUsername])

  return <UsernameForm username={username || prefilledUsername} onSubmit={onSubmit} />
}
