export const authenticateWithToastUserToken = async (
  token: string,
  isReauth: boolean,
  returnUrl?: string
): Promise<string | undefined> => {
  try {
    const response = await fetch(`/Login/Index`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        isReauth,
        returnUrl
      })
    })
    return response.url
  } catch (e) {}
}
