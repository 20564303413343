import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ToastUser } from '@toasttab/ec-banquet-props'

const QueryKey = ['me']

export type PayrollSession = {
  user: ToastUser
}

export const useClearPayrollSession = () => {
  const client = useQueryClient()

  return {
    clearPayrollSession: () => client.setQueryData(QueryKey, () => null)
  }
}

type PayrollSessionQueryResult =
  | {
      isCheckingPayrollSession: boolean
      hasSession: true
      user: ToastUser
    }
  | { isCheckingPayrollSession: boolean; hasSession: false; user: undefined }

const usePayrollSessionQuery = (
  enabled: boolean
): PayrollSessionQueryResult => {
  const query = useQuery<PayrollSession | null>({
    queryKey: QueryKey,
    enabled,

    queryFn: async () => {
      try {
        const resp = await fetch(`/me`, {
          credentials: 'same-origin',
          redirect: 'error'
        })

        const data = await resp.json()

        if (data) {
          return data
        }
      } catch (error) {
        console.info(
          'attempted to restore payroll session and the user does not have an active payroll session'
        )
      }

      return undefined
    },
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  })

  const hasSession = query.data?.user !== undefined

  if (hasSession) {
    return {
      isCheckingPayrollSession: query.isLoading,
      hasSession: true,
      user: query.data?.user!
    }
  } else {
    return {
      isCheckingPayrollSession: query.isLoading,
      hasSession: false,
      user: undefined
    }
  }
}

export { usePayrollSessionQuery }
