import * as React from 'react'
import {
  BrowserRouter,
  createSearchParams,
  Navigate,
  resolvePath,
  useRoutes
} from 'react-router-dom'
import { EcTitle } from '@toasttab/ec-title'
import { Apollo } from '@local/api/apollo'
import SystemProviders from './system/Providers'
import { PageLayout, SignInApp } from '../app'
import { AppErrorBoundary } from './components/errors/AppErrorBoundary'
import { useTranslation } from 'react-i18next'
import { ResetPasswordApp } from './ResetPassword/ResetPasswordApp'

export function App() {
  return (
    <AppErrorBoundary>
      <BrowserRouter>
        <Apollo>
          <SystemProviders>
            <AppRoutes />
          </SystemProviders>
        </Apollo>
      </BrowserRouter>
    </AppErrorBoundary>
  )
}

const NavigateToSignIn = () => {
  const to = resolvePath({
    pathname: '/sign-in',
    search: createSearchParams(window.location.search).toString()
  })
  return <Navigate to={to} />
}

export function AppRoutes() {
  const { t } = useTranslation('login')

  const routes = useRoutes([
    {
      path: '/sign-in',
      children: [
        {
          index: true,
          element: (
            <EcTitle title={t('signIn')}>
              <SignInApp />
            </EcTitle>
          )
        },
        {
          path: 'reset-password',
          element: (
            <EcTitle title={t('resetPassword')}>
              <ResetPasswordApp />
            </EcTitle>
          )
        },
        { path: '*', element: <Navigate to='/sign-in' /> }
      ]
    },
    {
      path: '/companyCode/*',
      element: <NavigateToSignIn />
    }
  ])

  return (
    <React.Suspense fallback={<div></div>}>
      <PageLayout>{routes}</PageLayout>
    </React.Suspense>
  )
}
