import * as React from 'react'
import { useEffect, useMemo, useReducer } from 'react'
import { SessionContextProvider } from './session/SessionProvider'
import { SignIn } from './SignIn'
import { useEcBanquetProps } from '@toasttab/ec-banquet-props'
import { sessionReducer } from './session/reducer'
import { InitialState, Login } from './session/state'
import { useSignInSearchParams } from '../hooks'
import { useNavigate } from 'react-router-dom'

export const SignInApp = () => {
  const { token } = useEcBanquetProps()
  const { returnUrl, isReauth } = useSignInSearchParams()
  const params = new URLSearchParams(window.location.search)
  const errorCodeParam = params.get('errorCode')
  const prefilledUsername = params.get('username') || undefined
  const errorCode = (errorCodeParam) ? parseInt(errorCodeParam) : undefined
  const toastUserToken = token && !isReauth ? token : undefined
  const navigate = useNavigate()

  useEffect(() => {
    if (errorCode || prefilledUsername) {
      navigate('/sign-in', {
          replace: true
      })
    }
  }, [errorCode, prefilledUsername])

  const initialState = useMemo(() => {
    if (errorCode === undefined && prefilledUsername == undefined) {
      return {
        type: 'initial-state',
        toastUserToken: toastUserToken,
        returnUrl: returnUrl,
        isReauth: isReauth
      } as InitialState
    }
    else {
      return {
        type: 'login',
        returnUrl: returnUrl,
        isReauth: isReauth,
        errorCode: errorCode,
        prefilledUsername: prefilledUsername
      } as Login
    }
  }, [returnUrl, isReauth, toastUserToken, errorCode, prefilledUsername])

  const [state, dispatch] = useReducer(sessionReducer, initialState)

  return (
    <SessionContextProvider state={state} dispatch={dispatch}>
      <SignIn />
    </SessionContextProvider>
  )
}
