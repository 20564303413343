import * as React from 'react'
import Carousel from './carousel/Carousel'
import { LanguageSwitcher } from '../../app/components/LanguageSwitcher'
import cx from 'classnames'
import { useWindowSize } from '../hooks'
import { TroubleLoggingIn } from './TroubleLoggingIn'

const MIN_HEIGHT = 760
const MOBILE_WIDTH = 850

type WrapperProps = {
  isMobile: boolean
  children: React.ReactNode
}

const Wrapper = (props: WrapperProps) => {
  const { isMobile, children } = props
  return (
    <div
      className={cx(
        'flex',
        isMobile ? 'flex-col' : 'flex-row-reverse items-stretch'
      )}
    >
      {children}
    </div>
  )
}

type BlockProps = {
  isMain?: boolean
  isMobile: boolean
  isShort: boolean
  children: React.ReactNode
}

const Block = (props: BlockProps) => {
  const { isMain, isMobile, isShort, children } = props
  return (
    <div
      className={cx(
        'overflow-auto',
        isMobile ? 'w-full' : isMain ? 'w-2/5 grow' : 'w-3/5 grow shrink'
      )}
      style={{
        height: isShort ? `${MIN_HEIGHT}px` : '100vh', // 100vh === h-screen
        minWidth: !isMobile && isMain ? '480px' : undefined
      }}
    >
      {children}
    </div>
  )
}

type StableAreaProps = {
  isShort: boolean
  children: React.ReactNode
}

const StableArea = (props: StableAreaProps) => {
  const { isShort, children } = props
  return (
    <div
      className={cx(
        'flex flex-col flex-wrap items-center content-center justify-center',
        !isShort && 'h-screen'
      )}
    >
      <div
        className={cx('flex flex-col items-center justify-start', 'pt-4')}
        style={{ minHeight: `${MIN_HEIGHT}px` }}
      >
        {children}
      </div>
    </div>
  )
}

type Props = {
  children: React.ReactNode
}

const PageLayout = (props: Props) => {
  const { children } = props

  const { width, height } = useWindowSize()

  const isShort = height < MIN_HEIGHT

  const isMobile = width < MOBILE_WIDTH

  return (
    <Wrapper isMobile={isMobile}>
      <Block isMain isMobile={isMobile} isShort={isShort}>
        <StableArea isShort={isShort}>
          <LanguageSwitcher />

          {children}

          <TroubleLoggingIn />
        </StableArea>
      </Block>

      {!isMobile && (
        <Block isMobile={isMobile} isShort={isShort}>
          <Carousel />
        </Block>
      )}
    </Wrapper>
  )
}

export default PageLayout
