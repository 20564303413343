import { SessionState } from './state'
import { SessionAction } from './actions'
import * as React from 'react'

export const sessionReducer = (
  state: SessionState,
  action: SessionAction
): SessionState => {
  switch (state.type) {
    case 'initial-state':
      switch (action.type) {
        case 'payroll-session-found':
          if (state.isReauth) {
            return {
              type: 'login',
              returnUrl: state.returnUrl,
              isReauth: true
            }
          } else {
            return {
              type: 'payroll-session-exists',
              user: action.user,
              returnUrl: state.returnUrl,
              isReauth: state.isReauth
            }
          }

        case 'payroll-session-not-found':
          if (state.toastUserToken && !state.isReauth) {
            return {
              type: 'authenticate-toast-user-token',
              toastUserToken: state.toastUserToken,
              returnUrl: state.returnUrl,
              isReauth: state.isReauth
            }
          } else {
            return {
              type: 'login',
              returnUrl: state.returnUrl,
              isReauth: state.isReauth
            }
          }
      }
      throw new Error(`Invalid action ${action.type} for state ${state.type}`)
    case 'payroll-session-exists':
      switch (action.type) {
        case 'log-out':
          return {
            type: 'logging-out',
            user: state.user
          }
      }
      throw new Error(`Invalid action ${action.type} for state ${state.type}`)
    case 'authenticate-toast-user-token':
      switch (action.type) {
        case 'toast-user-token-authentication-succeeded':
          return {
            type: 'login-in-progress',
            url: action.url
          }
        case 'toast-user-token-authentication-failed':
          return {
            type: 'login',
            isReauth: state.isReauth,
            returnUrl: state.returnUrl,
            errorCode: action.errorCode
          }
      }
      throw new Error(`Invalid action ${action.type} for state ${state.type}`)
  }
  throw new Error(`Invalid action ${action.type} for state ${state.type}`)
}

export type SessionDispatch = React.Dispatch<SessionAction>
