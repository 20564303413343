const en = {
  error: {
    companyCodeNotFound: "We couldn't find your company code. If you are an employee, please reach out to your manager.",
    customerAccessNotAllowed:
      'The company code you are trying to log in to is currently not active on Toast Payroll. If you are an employee, please reach out to your restaurant manager for next steps.',
    expiredPassword: 'Your password has expired. Please reset your password',
    expiredPasswordLink: 'Password reset',
    generic: 'An error occurred. If the problem persists, please contact support',
    invalidToken: 'The token you entered is incorrect. Please try again',
    ipAddressRestriction: 'There is a problem with your account. If you are an employee, please reach out to your manager.',
    legacyUsernameProvided: 'Please sign in with your email address',
    loginNotAvailableTitle: 'Login not available',
    rateLimit: 'Try again in a few minutes.',
    rateLimitTitle: 'Something went wrong',
    resetPassword:
      'An error occurred sending a password reset email request. Please contact support.',
    resetPasswordUnknown: 'Something went wrong. Please contact support',
    singleSignOnEnabled:
      'Password reset error. Company has single sign-on enabled. Please contact your manager.',
    translationFailed: 'Failed to save translation settings',
    unauthorized: 'Wrong username or password',
    unknown: 'Something went wrong',
    userNotFound: "We couldn't find your Payroll account. If you are an employee, please reach out to your manager.",
    logInWithPayrollCredentials: "Please log in using your Payroll username and password"
  },
  success: {
    passwordReset: `We've sent you an email with reset instructions. If you don't see it in your inbox within 2 hours, check your spam folder and try resetting your password again.`,
    passwordSuccess: 'Password has been successfully reset.'
  },
  back: 'Back',
  cantAccessEmail: "Can't access your email?",
  checkingExistingSessions: 'Checking for existing sessions',
  companyCode: 'Company Code',
  companyCodeHelpPrompt: 'Company Code?',
  companyCode_required: 'Company code is required',
  contactManager: 'Please contact your manager to find your company code.',
  contactManagerEmail:
    'Please contact your manager to set a valid email address for your Toast Payroll account',
  continueToPayroll: 'Continue to Payroll',
  customerId_required: 'Customer ID is required',
  customerUuid_required: 'Customer UUID is required',
  dontRememberContactManager:
    "If you don't remember your Company Code, contact your manager.",
  dontRememberYourPassword: 'Forgot password?',
  dontRememberYourPasswordInstructions: `We'll send an email to help reset your password and get you back on track`,
  email: 'Email',
  emailMultipleRestaurants:
    'Your email is associated with more than one restaurant',
  emailUsername: 'Email or Username',
  email_required: 'Email or Username is required',
  enterCompanyCode: 'Enter your company code',
  enterPassword: 'Enter your password',
  enterToken: 'Please enter the login token we just sent to your email',
  enterUsernameOrEmail: 'Enter your email or username to login',
  forceResetPassword: 'Reset your password',
  forceResetPasswordInstructions: `Request a password reset, and we'll send an email to help get you back on track.`,
  forgotCompanyCode: "Don't remember your company code?",
  forgotPassword: 'Forgot your password',
  loggedInAs: 'Logged in as "{{username}}"',
  loggingIn: 'Logging in',
  loggingInViaToast: 'Logging in via Toast',
  loggingOut: 'Logging out',
  next: 'Next',
  password: 'Password',
  passwordReset: 'Password reset',
  password_required: 'Password is required',
  usePayrollLogin: 'Use a different Payroll account',
  recaptcha_required: 'ReCaptcha is required',
  resend: 'Resend',
  resendEmail: 'Resend Email',
  resetEmailInstructions: `A link to reset your password has been emailed to you. If you can’t see this email, check your spam folder or try resetting your password again.`,
  resetEmailSent: `We’ve sent you an email`,
  resetPassword: 'Reset Password',
  resetPasswordPrompt: `We'll send you an email with a link to reset your password`,
  resetPasswordWithToast: 'Redirecting you to Toast',
  resettingPassword: 'Resetting Password',
  securityUpdate: 'Security Update',
  securityUpdateSubtitle: `Toast has updated its security procedures. We have emailed a password reset link to {{email}}. If you can’t find it in your inbox, please check your spam folder.`,
  send: 'Send',
  signIn: 'Sign In',
  signOut: 'Sign Out',
  switchToEmailToken: 'Switch to email token',
  token: 'Token',
  token_required: 'Token is required',
  troubleLoggingIn: 'Trouble logging in?',
  twoStepVerification: 'Two Step Verification',
  userUuid_required: 'User UUID is required',
  username: 'Username',
  welcome: 'Welcome back'
}

type Translations = typeof en

export type { Translations }

export { en }
