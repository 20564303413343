import { Login } from './Login'
import { LoginContextProvider } from './LoginProvider'
import * as React from 'react'
import { useReducer } from 'react'
import { loginReducer } from './reducer'
import { getSavedCompanyCode } from '../../helpers'
import { LoginError, WaitForUsername } from './state'

export const LoginApp = (
  props: {
    isReauth: boolean
    returnUrl?: string
    errorCode?: number
    prefilledUsername?: string
  }
) => {
  const { isReauth, returnUrl, errorCode, prefilledUsername } = props

  const initialState = (() => {
    if (errorCode === undefined) {
      return {
        type: 'wait-for-username',
        companyCode: getSavedCompanyCode(),
        returnUrl: returnUrl,
        isReauth: isReauth,
        prefilledUsername: prefilledUsername
      } as WaitForUsername
    } else {
      return {
        type: 'login-error',
        companyCode: getSavedCompanyCode(),
        returnUrl: returnUrl,
        isReauth: isReauth,
        errorCode: errorCode
      } as LoginError
    }
  })()

  const [state, dispatch] = useReducer(loginReducer, initialState)

  return (
    <LoginContextProvider state={state} dispatch={dispatch}>
      <Login />
    </LoginContextProvider>
  )
}
