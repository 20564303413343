import * as React from 'react'
import { useEcTitle } from '@toasttab/ec-title'
import { useTranslation } from 'react-i18next'
import { ActionsBar, FormLayout } from '../../components'
import { Form, FormikProvider, useFormik } from 'formik'
import { Button } from '@toasttab/buffet-pui-buttons'
import { UsernameInput } from '../../components/UsernameInput'
import { CompanyCodeInput } from '../../components/CompanyCodeInput'
import { useSessionDispatch } from './SessionProvider'
import { PayrollSession } from '../../helpers'

export const PayrollSessionExists = (props: { user: PayrollSession }) => {
  const dispatch = useSessionDispatch()

  const {
    user: { username, companyCode }
  } = props

  const { t } = useTranslation('login')

  const title = t('welcome')

  useEcTitle(title)

  const formik = useFormik({
    initialValues: { username, companyCode },
    onSubmit: () => {}
  })

  return (
    <FormLayout title={title} subtitle={t('loggedInAs', { username })}>
      <FormikProvider value={formik}>
        <Form>
          <UsernameInput disabled={true} />
          <CompanyCodeInput disabled={true} />
          <ActionsBar
            left={
              <Button
                testId={'signOut'}
                className='self-start'
                variant='text-link'
                onClick={() => dispatch({ type: 'log-out' })}
                disabled={formik.isSubmitting}
              >
                {t('signOut')}
              </Button>
            }
          >
            <Button
              className='self-end'
              id='next'
              as='a'
              testId='next'
              data-toast-track-id='next'
              href='/'
            >
              {t('continueToPayroll')}
            </Button>
          </ActionsBar>
        </Form>
      </FormikProvider>
    </FormLayout>
  )
}
