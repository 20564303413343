import * as React from 'react'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { useLanguageService } from '@local/services'

export const LanguageSwitcher = () => {
  const isSpanish = useLanguageService((s) => s.isSpanish())
  const switchLanguage = useLanguageService((s) => s.switchLanguage)

  return (
    <div className='flex justify-center mb-4'>
      <div>
        <TextToggleSwitch
          label=''
          name='language'
          options={['English', 'Español']}
          isActive={isSpanish}
          onChange={switchLanguage}
          className='w-48'
        />
      </div>
    </div>
  )
}
