import * as React from 'react'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { CardContainer } from '@toasttab/buffet-pui-card'
import Logo from '../../app/assets/logo.svg'

type Props = React.PropsWithChildren<{
  title: string
  subtitle?: string | null // null is annoyingly one of the return types of the t() function
  error?: string
  successMessage?: React.ReactNode | null
  infoMessage?: string | null
}>

const FormLayout = (props: Props) => {
  const { children, title, subtitle, error, infoMessage, successMessage } =
    props

  return (
    <CardContainer
      className='w-full space-y-8'
      style={{ minWidth: '400px', maxWidth: '400px' }}
    >
      <div className='pt-8'>
        <div className='flex flex-col items-center pb-8 space-y-8'>
          <Logo />

          <div className='space-y-2 text-center'>
            <div className='type-headline-4 welcome'>{title}</div>
            {subtitle && <div className='type-subhead'>{subtitle}</div>}
          </div>
        </div>

        {error ? (
          <Alert
            variant='error'
            className='w-full'
          >
            {error}
          </Alert>
        ) : successMessage ? (
          <Alert variant='success' className='w-full'>
            {successMessage}
          </Alert>
        ) : infoMessage ? (
          <Alert variant='info' className='w-full'>
            {infoMessage}
          </Alert>
        ) : null}

        {children}
      </div>
    </CardContainer>
  )
}

export default FormLayout
