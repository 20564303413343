import { useEcTitle } from '@toasttab/ec-title'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useCallback } from 'react'
import { UsernameForm, UsernameFormData } from './UsernameForm'
import { useRedirectToUrl } from '../../../hooks'
import { useEcProps } from '@toasttab/ec-banquet-props'

const codeToError = (code: number) =>
{
  switch (code) {
    case -8:
      return 'error.ipAddressRestriction'
    case -11:
    case -3:
      return 'error.userNotFound'
    case -20:
      return 'error.logInWithPayrollCredentials'
    default:
      return 'error.generic'
  }
}

export const LoginError = (
  props: {
    errorCode?: number
    companyCode?: string
    returnUrl?: string
    isReauth: boolean
  }
) => {
  const { errorCode, companyCode, isReauth, returnUrl } = props
  const { t } = useTranslation('login')
  const { redirectToUrl } = useRedirectToUrl()
  const { auth0ClientId } = useEcProps()

  const error = (errorCode) ? t(codeToError(errorCode)) : undefined

  useEcTitle(t('username'))

  const onSubmit = useCallback(
    async (data: UsernameFormData) => {
        const hostname = window.location.hostname
        const toastHostname = hostname.replace('payroll.', '')
        const formUsername = data.username.trim()

        const params: Record<string, any> = {
          username: formUsername,
          companyCode: companyCode,
          returnUrl: returnUrl,
          isReauth: isReauth
        }

        const keysAndEncodedValues = Object.keys(params).map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`
        })

        if (errorCode == -20) {
          const encodedUsername = encodeURIComponent(formUsername)
          redirectToUrl(`https://${hostname}/sign-in?username=${encodedUsername}`)
        }
        else {
          const queryString = keysAndEncodedValues.join('&')
          const encodedReturnUrl = encodeURIComponent(`https://${hostname}/sign-in?${queryString}`)

          redirectToUrl(`https://${toastHostname}/logout?clientId=${auth0ClientId}&returnUrl=${encodedReturnUrl}`)
        }
    },
    [errorCode, returnUrl, companyCode, isReauth]
  )

  return <UsernameForm onSubmit={onSubmit} error={error} />
}
